import React from 'react';
import Layout from '../../components/Layout';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const TermsAndPrivacy = () => {
  return (
    <Layout title={{ id: 'privacy.seo.title' }} description={{ id: 'privacy.seo.description' }}>
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42">Privacy policy</h1>
            <div className="subsection">
              <h2 className="subsection__title">Introduction</h2>
              <p className="subsection__par">
                This privacy policy sets out how TEFL Iberia uses and protects any information that
                you give TEFL Iberia when you use this website.
              </p>
              <p className="subsection__par">
                We are committed to ensuring that your privacy is protected. Should we ask you to
                provide certain information by which you can be identified when using this website,
                then you can be assured that it will only be used in accordance with this privacy
                statement.
              </p>
              <p className="subsection__par">
                We may change this policy from time to time by updating this page. We will
                communicate any changes to you by posting a note on our website. This updated policy
                is effective from 25th May 2018.
              </p>
            </div>

            <div className="subsection">
              <h2 className="subsection__title">
                Who is responsible for processing your personal data?
              </h2>
              <p className="subsection__par">
                TEFL Iberia, CIF: X9870960G. Address: Calle Valencia 275, 3º, Barcelona 08009.
                Phone: (+34) 934875116. Email: info@tefl-iberia.com
              </p>
            </div>

            <div className="subsection">
              <h2 className="subsection__title">
                Who is responsible for processing your personal data?
              </h2>
              <ul className="subsection__list">
                <li className="item">First name and surname</li>
                <li className="item">Postal address (including billing and shipping addresses)</li>
                <li className="item">Phone number (including home and mobile phone numbers)</li>
                <li className="item">Email address</li>
                <li className="item">Country of residence</li>
                <li className="item">IP address</li>
                <li className="item">Browser and device information</li>
                <li className="item">Server log file information</li>
                <li className="item">
                  Information collected through cookies, pixel tags and other technologies
                </li>
                <li className="item">
                  Demographic information and other information provided by you
                </li>
                <li className="item">Location information</li>
              </ul>
            </div>

            <div className="subsection">
              <h2 className="subsection__title">How do we collect your data?</h2>
              <p className="subsection__par subsection__par--sm">
                <span className="text-fw-600">Through our website:</span> when you complete a
                contact form, for example.
              </p>
              <p className="subsection__par subsection__par--sm">
                <span className="text-fw-600">Offline: </span>
                when you call or visit the school.
              </p>
              <p className="subsection__par subsection__par--sm">
                <span className="text-fw-600">Through your browser or device:</span> certain
                information is collected by most browsers, or automatically through your device. We
                use this information for statistical purposes and to ensure that our website
                functions correctly.
              </p>
              <p className="subsection__par subsection__par--sm">
                <span className="text-fw-600">From other sources:</span> from partner schools and
                organisations, for example if you apply to TEFL Iberia via GoAbroad or an advert on
                Facebook.
              </p>
            </div>

            <div className="subsection">
              <h2 className="subsection__title">What we do with the information we gather</h2>
              <p className="subsection__par subsection__par--sm">
                We require this information to understand your needs and provide you with a better
                service, and in particular for the following reasons:
              </p>
              <ul className="subsection__list">
                <li className="item">Internal record keeping.</li>
                <li className="item">
                  We may use the information to improve our products and services.
                </li>
                <li className="item">
                  We may periodically send promotional emails about new products, special offers or
                  other information which we think you may find interesting using the email address
                  which you have provided.
                </li>
                <li className="item">
                  From time to time, we may also use your information to contact you for market
                  research purposes. We may contact you by email or phone. We may use the
                  information to customise the website according to your interests.
                </li>
              </ul>
            </div>

            <div className="subsection">
              <h1 className="subsection__title">Security</h1>
              <p className="subsection__par">
                We are committed to ensuring that your information is secure. In order to prevent
                unauthorised access or disclosure, we have put in place suitable physical,
                electronic and managerial procedures to safeguard and secure the information we
                collect online.
              </p>
            </div>
            <div className="subsection">
              <h2 className="subsection__title">How we use cookies</h2>
              <p className="subsection__par">
                A cookie is a small file which asks permission to be placed on your computer’s hard
                drive. Once you agree, the file is added and the cookie helps analyse web traffic or
                lets you know when you visit a particular site. Cookies allow web applications to
                respond to you as an individual. The web application can tailor its operations to
                your needs, likes and dislikes by gathering and remembering information about your
                preferences.
              </p>
              <p className="subsection__par">
                We use traffic log cookies to identify which pages are being used. This helps us
                analyse data about webpage traffic and improve our website in order to tailor it to
                customer needs. We only use this information for statistical analysis purposes and
                then the data is removed from the system.
              </p>
              <p className="subsection__par">
                Overall, cookies help us provide you with a better website by enabling us to monitor
                which pages you find useful and which you do not. A cookie in no way gives us access
                to your computer or any information about you, other than the data you choose to
                share with us.
              </p>
              <p className="subsection__par">
                You can choose to accept or decline cookies. Most web browsers automatically accept
                cookies, but you can usually modify your browser setting to decline cookies if you
                prefer. This may prevent you from taking full advantage of the website.
              </p>
            </div>
            <div className="subsection">
              <h2 className="subsection__title">Links to other websites</h2>
              <p className="subsection__par">
                Our website may contain links to other websites of interest. However, once you have
                used these links to leave our site, you should note that we do not have any control
                over that other website. Therefore, we cannot be responsible for the protection and
                privacy of any information which you provide whilst visiting such sites and such
                sites are not governed by this privacy statement. You should exercise caution and
                look at the privacy statement applicable to the website in question.
              </p>
            </div>
            <div className="subsection">
              <h2 className="subsection__title">How long will we keep your data?</h2>
              <p className="subsection__par">
                The personal data you provide will be kept as long as you do not request it to be
                deleted.
              </p>
            </div>
            <div className="subsection">
              <h2 className="subsection__title">
                Which organisations will your information be passed on to?
              </h2>
              <p className="subsection__par">
                The only organisations we pass your data on to are ones closely linked to TEFL
                Iberia and the services we provide, such as accommodation hosts and courses run at
                partner schools.
              </p>
            </div>
            <div className="subsection">
              <h2 className="subsection__title">Social media</h2>
              <p className="subsection__par">
                Photos are regularly taken on TEFL Iberia premises and outside the school, relating
                to school activities. These may be published on our social media channels. You have
                the right to request that your image is not used on our channels.
              </p>
            </div>
            <div className="subsection">
              <h2 className="subsection__title">Controlling your personal information</h2>
              <p className="subsection__par subsection__par--sm">
                You may choose to restrict the collection or use of your personal information in the
                following ways:
              </p>
              <ul className="subsection__list">
                <li className="item">
                  if you have previously agreed to us using your personal information for direct
                  marketing purposes, you may change your mind at any time by writing to or emailing
                  us at info@tefl-iberia.com.
                </li>
                <li className="item">
                  We will not sell, distribute or lease your personal information to third parties
                  unless we have your permission or are required by law to do so. We may use your
                  personal information to send you promotional information about third parties which
                  we think you may find interesting if you tell us that you wish this to happen.
                </li>
              </ul>
              <p className="mt-20 item subsection__par subsection__par--sm">
                If you believe that any information we are holding on you is incorrect or
                incomplete, please write to or email us as soon as possible at the above address. We
                will promptly correct any information found to be incorrect.
              </p>
            </div>
            <div className="subsection">
              <h2 className="subsection__title">Note</h2>
              <p className="subsection__par subsection__par--sm">
                Browsing this website, or using any of the services provided through it,{' '}
                <span className="text-fw-600">implies your acceptance</span> of the policy described
                on these pages.
              </p>
              <p className="subsection__par subsection__par--sm">
                Last update: May 25th 2018, in compliance with General Data Protection Regulation
                (EU) 2016/679.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndPrivacy;
